@import 'variables';
@import 'mixins';

.w3-topbar {
  border-top-width: $bottom-bar-width !important;
}
.w3-bottombar {
  border-bottom-width: $bottom-bar-width !important;
}
.w3-leftbar {
  border-left-width: $bottom-bar-width !important;
}
.w3-rightbar {
  border-right-width: $bottom-bar-width !important;
}

.margin-bottom-0 {
  margin-bottom:0px;
}

.margin-top-0 {
  margin-top:0px;
}


@include font-sizes();

@media (min-width: 1440px) {
  .w3-col.xl1 {
    width: 8.33333%;
  }
  .w3-col.xl2 {
    width: 16.66666%;
  }
  .w3-col.xl3,
  .w3-quarter {
    width: 24.99999%;
  }
  .w3-col.xl4,
  .w3-third {
    width: 33.33333%;
  }
  .w3-col.xl5 {
    width: 41.66666%;
  }
  .w3-col.xl6,
  .w3-half {
    width: 49.99999%;
  }
  .w3-col.xl7 {
    width: 58.33333%;
  }
  .w3-col.xl8,
  .w3-twothird {
    width: 66.66666%;
  }
  .w3-col.xl9,
  .w3-threequarter {
    width: 74.99999%;
  }
  .w3-col.xl10 {
    width: 83.33333%;
  }
  .w3-col.xl11 {
    width: 91.66666%;
  }
  .w3-col.xl12 {
    width: 99.99999%;
  }
}

hr {
  border-top-width: 2px;
  color: $color-tertiary;
}

.w3-theme-tertiary-l5 {
  box-shadow: 0 1px 6px rgba(51,51,51,.1);
}