@import 'mixins';

@include paddington('xs', 0.25);
@include paddington('sm', 0.5);
@include paddington('md');
@include paddington('lg', 2);
@include paddington('xl', 4);
@include margington('xs', 0.25);
@include margington('sm', 0.5);
@include margington('md');
@include margington('lg', 2);
@include margington('xl', 4);

@media (max-width: 991px) {
  @include paddington('lg', 1);
}

body::-webkit-scrollbar {
  display: none;
}

.header-with-icon {
  display: inline;
}

/*header .w3-bar .w3-bar-item {
  &:first-child {
    padding-left: 0px;
  }
  &:last-child {
    padding-right: 0px;
  }
}*/

.fa-stack.stack-lg {
  font-size: 0.625em;
  width: 2em;
  .fa-stack-1x {
    background-color: white;
    top: 4.2px;
    margin-left: 4px;
    font-size: 1.13em;
  }
}

.dx-list-item.dx-state-hover, .dx-list-item.dx-state-focused {
  .fa-stack.stack-lg .fa-stack-1x {
    background-color: #efecea;
  }
}

.dx-data-row.dx-state-hover, .dx-state-hover.dx-state-focused {
  .fa-stack.stack-lg .fa-stack-1x {
    background-color: #e6e2df;
  }
}

.fa-layers .fa-layers-counter {
  background-color: $color-danger;
  padding: 2px 4px;
  border-radius: 64px;
  font-size:small;
  position: relative;
  bottom:-10px;
  left:-10px;
}

// padding-top: 0px;
//     padding-right: 0px;
//     padding-bottom: 0px;
//     padding-left: 5px;
