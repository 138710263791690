@import 'variables';
@import 'mixins';

// dx-filter-builder > .dx-filterbuilder-group > .dx-filterbuilder-group-item {
//   &::before {
//     content: 'OR';
//   }
//   & > .dx-filterbuilder-text.dx-filterbuilder-group-operation {
//     display: none;
//   }
// }

.dx-theme-material-typography {
  font-size: $base-font-size;
}

.dx-datagrid-nodata {
  color: $color-primary;
  opacity: 0.5;
}

.dx-tab {
  background-color: $color-tertiary;
}

.dx-tab.dx-tab-selected {
  background-color: #fff;
}

.dx-tab.dx-tab-selected::before {
  height: 3px;
  display: none;
}

.dx-drawer-content .main > .dx-scrollable {
  background-color: $color-tertiary;
}

.dx-toolbar {
  .dx-toolbar-label {
    font-weight: $base-font-weight;
    font-size: $font-size-small;
  }
  .dx-toolbar-items-container .dx-toolbar-item .toolbar-icon {
    width: 36px;
    height: 36px;
    display: inherit;
    vertical-align: middle;
    line-height: 0;
    text-align: center;
  }
}

.dx-fieldset {
  margin: 20px 0px;
}

.dx-fieldset-header {
  font-size: $font-size-large;
  font-weight: $font-weight-bold;
}
.dx-field,
.dx-field-label {
  font-size: $font-size-text;
}
.dx-field {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 8px;
  margin-left: 0px;
  &:last-of-type {
    margin-bottom: 8px;
  }
  &:last-child {
    margin: 0;
  }
}
.dx-field-value-static {
  padding-top: 5px;
  padding-right: 8px;
  padding-bottom: 5px;
  padding-left: 8px;
  align-items: center;
  display: flex;
}

.dx-button-text {
  @include font-sizes();

  .no-transform & {
    text-transform: unset;
    letter-spacing: unset;
  }
  .w3-text-white & {
    color: white !important;
  }
  font-weight: 400;
}

.w3-theme-primary {
  .dx-placeholder,
  .dx-texteditor-input {
    color: #fff;
  }
}

.dx-textbox .dx-placeholder {
  opacity: 0.5;
}

.dx-texteditor.dx-editor-filled::after {
  border-bottom-width: 3px;
}

.dx-show-clear-button .dx-icon-clear {
  color: $color-primary;
  background-color: transparent;
}

.dx-icon {
  .rotate-icon-90 & {
    transform: rotate(90deg);
  }
  .rotate-icon-180 & {
    transform: rotate(180deg);
  }
  .rotate-icon-270 & {
    transform: rotate(270deg);
  }
}

.dx-toolbar.dx-popup-title {
  border-bottom-width: 0px;
  // close button icon position fix
  .dx-closebutton > .dx-button-content {
    display: flex;
    .dx-icon-close {
      font-size: $font-size-large;
    }
  }
}

.dx-toolbar-items-container {
  display: flex;
  flex-wrap: wrap;
}

.dx-popup-title.dx-toolbar .dx-toolbar-items-container {
  height: 45px;
}

.dx-popup-content {
  padding-top: 0px;
}

.dx-toolbar-after {
  justify-self: right;
}
.dx-toolbar-before {
  justify-self: left;
}

.dx-selectbox,
.dx-dropdownbox {
  .dx-dropdowneditor-icon {
    background-image: url(~assets/img/icon_chevron.svg) !important;
    transform: rotate(90deg);
    &::before {
      content: '' !important;
    }
  }
  &.dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon {
    transform: rotate(270deg);
  }
}

.dx-list .dx-empty-message {
  padding: 6px 16px 5px;
}

dx-data-grid {
  .dx-datagrid-pager.dx-pager {
    .dx-pages {
      float: unset;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    //text-transform: uppercase;
    font-size: $font-size-text;
    line-height: $base-line-height;
    font-weight: $font-weight-bold;

    &.no-hover:hover:not(.dx-command-select):not(.dx-command-expand):not(
        .dx-editor-cell
      ):not(.dx-command-edit):not(.dx-datagrid-group-space) {
      background-color: unset;
    }
  }

  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(
      .dx-row-removed
    ):not(.dx-edit-row):not(.dx-row-focused)
    > td:not(.dx-focused) {
    background-color: #e6e2df;
  }
  /*.dx-data-row:hover:not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused) > td:not(.dx-focused) {
    background-color: rgba(0, 0, 0, 0.04);
  }*/

  // fix column sort overlap in icon columns
  .dx-header-row .icon-column {
    .dx-header-filter-indicator.dx-text-content-alignment-left,
    .dx-sort-indicator.dx-text-content-alignment-left {
      margin-right: -3px;
    }
    .dx-sort-indicator {
      max-width: 100%;
    }
  }

  .dx-datagrid {
    .dx-datagrid-table .dx-header-row > td {
      border-bottom-width: 1px;
      border-color: $color-secondary;
    }
    .dx-datagrid-header-panel {
      border-bottom-width: 0px;
    }
    .dx-row > td {
      font-size: $font-size-text;
    }
    .dx-datagrid-rowsview.dx-empty {
      height: 20px;
    }
  }

  &.has-title-toolbar {
    .dx-datagrid-header-panel {
      padding-left: 0px;
      .dx-toolbar-items-container {
        height: 56px;
      }
    }
  }
}

.dx-popup-title.no-border {
  border-bottom: none;
}

.dx-overlay-shader {
  background-color: transparent;
}
