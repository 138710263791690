@import './variables';

@mixin paddington($name, $factor: 1) {
  .padding-#{$name} {
    padding: $base-padding * $factor !important;
  }

  .padding-horizontal-#{$name} {
    padding-left: $base-padding * $factor !important;
    padding-right: $base-padding * $factor !important;
  }

  .padding-left-#{$name} {
    padding-left: $base-padding * $factor !important;
  }

  .padding-right-#{$name} {
    padding-right: $base-padding * $factor !important;
  }

  .padding-vertical-#{$name} {
    padding-top: $base-padding * $factor !important;
    padding-bottom: $base-padding * $factor !important;
  }

  .padding-top-#{$name} {
    padding-top: $base-padding * $factor !important;
  }

  .padding-bottom-#{$name} {
    padding-bottom: $base-padding * $factor !important;
  }
}

@mixin margington($name, $factor: 1) {
  .margin-#{$name} {
    margin: $base-margin * $factor !important;
  }

  .margin-horizontal-#{$name} {
    margin-left: $base-margin * $factor !important;
    margin-right: $base-margin * $factor !important;
  }

  .margin-left-#{$name} {
    margin-left: $base-margin * $factor !important;
  }

  .margin-right-#{$name} {
    margin-right: $base-margin * $factor !important;
  }

  .margin-vertical-#{$name} {
    margin-top: $base-margin * $factor !important;
    margin-bottom: $base-margin * $factor !important;
  }

  .margin-top-#{$name} {
    margin-top: $base-margin * $factor !important;
  }

  .margin-bottom-#{$name} {
    margin-bottom: $base-margin * $factor !important;
  }
}

@mixin font-sizes() {
  .w3-xlarge {
    font-size: $font-size-header !important;
  }
  .w3-large {
    font-size: $font-size-large !important;
  }
  .w3-medium {
    font-size: $font-size-text !important;
  }
  .w3-small {
    font-size: $font-size-small !important;
  }
  @media (max-width: 668px) {
    .text-responsive.w3-medium, .text-responsive.w3-medium span{
      font-size: $font-size-small !important;
    }
  }
}

@mixin devextreme-font-size() {
  ::ng-deep {
    @include font-sizes();
  }
}

// Mixin:

// @mixin prefix($property, $value, $prefixes) {
//   @each $prefix in $prefixes {
//     -#{$prefix}-#{$property}: $value;
//   }
//   #{$property}: $value;
// }

// Usage:

// .gray {
//   @include prefix(filter, grayscale(50%), moz webkit);
// }

// Result:

// .gray {
//   -moz-filter: grayscale(50%);
//   -webkit-filter: grayscale(50%);
//   filter: grayscale(50%);
// }
