.w3-theme-secondary-l5 {
  color: #162a4b !important;
  background-color: #fcf9f5 !important;
}
.w3-theme-secondary-l4 {
  color: #162a4b !important;
  background-color: #f5ecdc !important;
}
.w3-theme-secondary-l3 {
  color: #162a4b !important;
  background-color: #ead9ba !important;
}
.w3-theme-secondary-l2 {
  color: #162a4b !important;
  background-color: #e0c597 !important;
}
.w3-theme-secondary-l1 {
  color: #162a4b !important;
  background-color: #d6b275 !important;
}
.w3-theme-secondary-d1 {
  color: #fff !important;
  background-color: #c5933c !important;
}
.w3-theme-secondary-d2 {
  color: #fff !important;
  background-color: #b08335 !important;
}
.w3-theme-secondary-d3 {
  color: #fff !important;
  background-color: #9a722e !important;
}
.w3-theme-secondary-d4 {
  color: #fff !important;
  background-color: #846227 !important;
}
.w3-theme-secondary-d5 {
  color: #fff !important;
  background-color: #6e5221 !important;
}

.w3-theme-secondary-light {
  color: #162a4b !important;
  background-color: #fcf9f5 !important;
}
.w3-theme-secondary-dark {
  color: #fff !important;
  background-color: #6e5221 !important;
}
.w3-theme-secondary-action {
  color: #fff !important;
  background-color: #6e5221 !important;
}

.w3-theme-secondary {
  color: #162a4b !important;
  background-color: #cc9f53 !important;
}
.w3-text-theme-secondary {
  color: #cc9f53 !important;
}
.w3-border-theme-secondary {
  border-color: #cc9f53 !important;
}

.w3-hover-theme-secondary:hover {
  color: #162a4b !important;
  background-color: #cc9f53 !important;
}
.w3-hover-text-theme-secondary:hover {
  color: #cc9f53 !important;
}
.w3-hover-border-theme-secondary:hover {
  border-color: #cc9f53 !important;
}
