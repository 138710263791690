.w3-theme-tertiary-l5 {
  color: #162a4b !important;
  background-color: #fefefe !important;
}
.w3-theme-tertiary-l4 {
  color: #162a4b !important;
  background-color: #fcfbfb !important;
}
.w3-theme-tertiary-l3 {
  color: #162a4b !important;
  background-color: #f9f8f7 !important;
}
.w3-theme-tertiary-l2 {
  color: #162a4b !important;
  background-color: #f6f4f3 !important;
}
.w3-theme-tertiary-l1 {
  color: #162a4b !important;
  background-color: #f3f0ef !important;
}
.w3-theme-tertiary-d1 {
  color: #162a4b !important;
  background-color: #dbd4d0 !important;
}
.w3-theme-tertiary-d2 {
  color: #162a4b !important;
  background-color: #c7bcb5 !important;
}
.w3-theme-tertiary-d3 {
  color: #162a4b !important;
  background-color: #b2a49a !important;
}
.w3-theme-tertiary-d4 {
  color: #fff !important;
  background-color: #9e8b7f !important;
}
.w3-theme-tertiary-d5 {
  color: #fff !important;
  background-color: #877366 !important;
}

.w3-theme-tertiary-light {
  color: #162a4b !important;
  background-color: #fefefe !important;
}
.w3-theme-tertiary-dark {
  color: #fff !important;
  background-color: #877366 !important;
}
.w3-theme-tertiary-action {
  color: #fff !important;
  background-color: #877366 !important;
}

.w3-theme-tertiary {
  color: #162a4b !important;
  background-color: #efecea !important;
}
.w3-text-theme-tertiary {
  color: #efecea !important;
}
.w3-border-theme-tertiary {
  border-color: #efecea !important;
}

.w3-hover-theme-tertiary:hover {
  color: #162a4b !important;
  background-color: #efecea !important;
}
.w3-hover-text-theme-tertiary:hover {
  color: #efecea !important;
}
.w3-hover-border-theme-tertiary:hover {
  border-color: #efecea !important;
}
