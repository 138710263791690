.w3-theme-primary-l5 {
  color: #162a4b !important;
  background-color: #ecf1f9 !important;
}
.w3-theme-primary-l4 {
  color: #162a4b !important;
  background-color: #bfd0ec !important;
}
.w3-theme-primary-l3 {
  color: #fff !important;
  background-color: #7fa2da !important;
}
.w3-theme-primary-l2 {
  color: #fff !important;
  background-color: #3f73c7 !important;
}
.w3-theme-primary-l1 {
  color: #fff !important;
  background-color: #284e8b !important;
}
.w3-theme-primary-d1 {
  color: #fff !important;
  background-color: #142644 !important;
}
.w3-theme-primary-d2 {
  color: #fff !important;
  background-color: #11223c !important;
}
.w3-theme-primary-d3 {
  color: #fff !important;
  background-color: #0f1e35 !important;
}
.w3-theme-primary-d4 {
  color: #fff !important;
  background-color: #0d192d !important;
}
.w3-theme-primary-d5 {
  color: #fff !important;
  background-color: #0b1526 !important;
}

.w3-theme-primary-light {
  color: #162a4b !important;
  background-color: #fff !important;
}
.w3-theme-primary-dark {
  color: #fff !important;
  background-color: #0b1526 !important;
}
.w3-theme-primary-action {
  color: #fff !important;
  background-color: #0b1526 !important;
}

.w3-theme-primary {
  color: #fff !important;
  background-color: #162a4b !important;
}
.w3-text-theme-primary {
  color: #162a4b !important;
}
.w3-border-theme-primary {
  border-color: #162a4b !important;
}

.w3-hover-theme-primary:hover {
  color: #fff !important;
  background-color: #162a4b !important;
}
.w3-hover-text-theme-primary:hover {
  color: #162a4b !important;
}
.w3-hover-border-theme-primary:hover {
  border-color: #162a4b !important;
}
