@font-face {
    font-family: 'Source Sans Pro';
    src: url(~/fonts/SourceSansPro-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url(~/fonts/SourceSansPro-Regular.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url(~/fonts/SourceSansPro-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}