@import '../vendors/generated/variables.dx';

$color-primary: #162a4b;
$color-secondary: #cc9f53;
$color-tertiary: #efecea;
$color-success: $base-success;
$color-warning: $base-warning;
$color-danger: $base-danger;

$color-text-primary: #ffffff;
$color-text-secondary: #ffffff;
$color-text-tertiary: $color-primary;
$color-text-success: $color-primary;
$color-text-warning: $color-primary;
$color-text-danger: $color-primary;

$base-padding: 16px;
$base-margin: 16px;

$base-line-height: 1.25;
$base-font-size: 16px;
$base-font-weight: 400;

$font-weight-bold: 600;
$bottom-bar-width: 3px;

$font-size-text: $base-font-size;
$font-size-small: $base-font-size * 0.8125;
$font-size-large: $base-font-size * 1.25;
$font-size-header: $base-font-size * 1.5;
