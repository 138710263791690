.w3-theme-danger-l5 {
  color: #162a4b !important;
  background-color: #fbeef2 !important;
}
.w3-theme-danger-l4 {
  color: #162a4b !important;
  background-color: #f3c7d5 !important;
}
.w3-theme-danger-l3 {
  color: #162a4b !important;
  background-color: #e68fab !important;
}
.w3-theme-danger-l2 {
  color: #162a4b !important;
  background-color: #da5780 !important;
}
.w3-theme-danger-l1 {
  color: #162a4b !important;
  background-color: #c22b5b !important;
}
.w3-theme-danger-d1 {
  color: #fff !important;
  background-color: #7c1b3a !important;
}
.w3-theme-danger-d2 {
  color: #fff !important;
  background-color: #6e1834 !important;
}
.w3-theme-danger-d3 {
  color: #fff !important;
  background-color: #61152d !important;
}
.w3-theme-danger-d4 {
  color: #fff !important;
  background-color: #531227 !important;
}
.w3-theme-danger-d5 {
  color: #fff !important;
  background-color: #450f20 !important;
}

.w3-theme-danger-light {
  color: #162a4b !important;
  background-color: #fbeef2 !important;
}
.w3-theme-danger-dark {
  color: #fff !important;
  background-color: #450f20 !important;
}
.w3-theme-danger-action {
  color: #fff !important;
  background-color: #450f20 !important;
}

.w3-theme-danger {
  color: #162a4b !important;
  background-color: #8a1e41 !important;
}
.w3-text-theme-danger {
  color: #8a1e41 !important;
}
.w3-border-theme-danger {
  border-color: #8a1e41 !important;
}

.w3-hover-theme-danger:hover {
  color: #162a4b !important;
  background-color: #8a1e41 !important;
}
.w3-hover-text-theme-danger:hover {
  color: #8a1e41 !important;
}
.w3-hover-border-theme-danger:hover {
  border-color: #8a1e41 !important;
}
