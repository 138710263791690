@import 'variables';
@import 'mixins';

html,
body,
h1,
h2,
h3,
p,
span {
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
}

h1,
h2,
h3 {
  text-transform: uppercase;
  font-weight: $font-weight-bold;
}
h1 {
  font-size: $font-size-header;
}
h2 {
  font-size: $font-size-large;
}
h3 {
  font-size: $font-size-text;
}

@include font-sizes();
.text-bold {
  font-weight: $font-weight-bold !important;
}

.no-transform {
  text-decoration: none;
  text-transform: unset;
  letter-spacing: unset;
}

.hover-underline {
  text-decoration: none;
  text-transform: unset;
  letter-spacing: unset;
  &:hover {
    text-decoration: underline;
  }
}

.upper-case {
  text-transform: uppercase !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}
